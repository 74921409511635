@import url("https://fonts.googleapis.com/css2?family=Handlee&family=Montserrat:wght@300;400;500;600;700&family=Roboto+Slab:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
h1,
h2,
h3 {
  font-family: 'Roboto Slab', serif;
  color: #222; }

h2,
h3,
h4 {
  text-transform: uppercase;
  line-height: 1.2;
  color: #222; }

p,
li {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  line-height: 1.6;
  color: #222; }

.body-text {
  font-size: 1.6rem;
  color: #222; }

h1 {
  font-size: 8rem; }
  @media (max-width: 37.5em) {
    h1 {
      font-size: 3.5rem; } }

h2 {
  font-size: 3rem; }

h3 {
  font-size: 2rem; }

.section-heading {
  margin-bottom: 3rem;
  text-align: center; }

.card-heading {
  margin-bottom: 1.5rem; }

.container-centered-content {
  max-width: 96rem;
  margin: 0 auto;
  padding: 12rem 1.5rem; }

.container-flex {
  display: flex;
  justify-content: center;
  max-width: 96rem; }
  @media (max-width: 60em) {
    .container-flex {
      flex-direction: column; } }

.full-width {
  max-width: 100%; }

.box-shadow {
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25); }

.btn-outline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0.8rem 1.7rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 2rem;
  overflow: hidden;
  margin-top: 3rem;
  transition: all 1s; }
  .btn-outline:focus {
    outline: none; }

.btn-outline {
  width: 21rem;
  min-height: 5rem;
  background-color: transparent;
  color: #2e8540;
  border: 0.1rem solid #77cb5c; }
  .btn-outline:hover {
    background-color: #77cb5c;
    color: #222; }

.section-divider {
  max-width: 96rem;
  background-color: #77cb5c;
  border: none;
  margin: 0 auto;
  height: 0.1rem; }
  @media (max-width: 60em) {
    .section-divider {
      max-width: 80%; } }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 60em) {
    html {
      font-size: 55%; } }

body {
  background-color: #fff; }

ul {
  list-style: none; }

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(240, 240, 240, 0.8); }

.modal {
  position: fixed;
  z-index: 500;
  background-color: #fff;
  width: 65%;
  min-height: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1.5rem;
  left: 17.5%;
  top: 10%;
  transition: all 0.7s ease-out;
  border-radius: 0.2rem; }
  .modal-hide {
    opacity: 0;
    transform: translateY(-100vh); }
  .modal-show {
    opacity: 1;
    transform: translateY(0); }
  @media (max-width: 60em) {
    .modal {
      width: 90%;
      min-height: 35%;
      left: 5%; } }
  .modal button {
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    background-color: transparent; }
    .modal button:focus {
      outline: none; }
  .modal .close-icon {
    font-size: 3rem;
    color: #222;
    transition: all 1s; }
    .modal .close-icon:hover {
      transform: scale(1.1);
      color: red; }

.carousel {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%; }
  @media (max-width: 80em) {
    .carousel {
      min-height: 55rem; } }
  @media (max-width: 37.5em) {
    .carousel {
      min-height: 30rem; } }
  .carousel-close {
    display: none; }
  .carousel-open {
    display: contents; }
  .carousel-section-top {
    display: flex;
    justify-content: center; }
  .carousel-img {
    width: 100%;
    height: auto;
    object-fit: cover; }
  .carousel-chevron {
    color: #222;
    font-size: 4rem;
    margin: 0 1rem;
    cursor: pointer;
    user-select: none; }
    @media (max-width: 60em) {
      .carousel-chevron {
        margin: 0.5rem;
        font-size: 3rem; } }
    .carousel-chevron-left {
      position: fixed;
      left: 0.5rem;
      top: 43%; }
    .carousel-chevron-right {
      position: fixed;
      right: 0.5rem;
      top: 43%; }
  .carousel-section-bottom {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .carousel-section-bottom .item-count {
      font-size: 1.3rem; }
    @media (max-width: 37.5em) {
      .carousel-section-bottom .item-description {
        font-size: 1.5rem; } }

.nav {
  background: transparent;
  width: 100%;
  height: 7rem;
  position: fixed;
  top: 0;
  z-index: 50; }
  .nav-active {
    background-color: rgba(255, 255, 255, 0.8); }
  .nav-content {
    max-width: 90%;
    margin: auto;
    padding: 0 2rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .nav-content .logo-container {
      margin-top: 0.5rem;
      width: 6rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #77cb5c;
      border: 0.3rem solid;
      border-radius: 50%;
      text-decoration: none; }
      .nav-content .logo-container .logo-text {
        font-size: 3.5rem;
        color: #fff;
        font-family: "Roboto Slab", serif;
        padding-bottom: 1rem; }
    .nav-content ul {
      display: flex; }
      .nav-content ul li {
        padding: 1rem 2.25rem; }
        .nav-content ul li .nav-link {
          text-decoration: none;
          color: #222;
          border-bottom: 0.45rem transparent solid;
          transition: border-color 0.5s; }
          .nav-content ul li .nav-link:hover, .nav-content ul li .nav-link-active {
            border-color: #e0bf24; }

@media (max-width: 37.5em) {
  .nav-content {
    display: none; }
  .burger {
    display: inline-block;
    font-size: 4rem;
    padding: 1.3rem;
    color: #222;
    position: fixed;
    right: 0;
    top: 0; } }

.side-drawer {
  position: fixed;
  width: 20rem;
  max-width: 70%;
  height: 100%;
  background-color: #fff;
  right: 0;
  top: 0;
  z-index: 500;
  padding: 3rem 1.5rem;
  transition: transform 0.3s ease-out; }
  .side-drawer .logo {
    margin: 1.3rem; }
  .side-drawer ul {
    display: block; }
    .side-drawer ul li {
      padding: 1.5rem; }
      .side-drawer ul li .nav-link {
        text-decoration: none;
        color: #222;
        border-bottom: 0.45rem transparent solid;
        transition: border-color 0.5s; }
        .side-drawer ul li .nav-link:hover {
          color: #bd338e; }

@media (min-width: 37.5em) {
  .side-drawer,
  .burger {
    display: none; } }

.open-drawer {
  transform: translateX(0); }

.close-drawer {
  transform: translateX(100%); }

.hero {
  height: 95vh;
  background: #77cb5c;
  position: relative;
  overflow: hidden;
  color: #222;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .hero-intro {
    text-align: center; }
    .hero-intro h1.hero-text-name:before {
      content: '<h1>';
      padding-right: 1.5rem;
      font-family: 'Handlee', cursive;
      color: #97d783;
      font-size: 2rem; }
    .hero-intro h1.hero-text-name:after {
      content: '</h1>';
      padding-left: 1.5rem;
      font-family: 'Handlee', cursive;
      color: #97d783;
      font-size: 2rem; }
  .hero-flexbox {
    display: flex;
    margin: 5rem;
    max-width: 96rem; }
    @media (max-width: 60em) {
      .hero-flexbox {
        flex-direction: column-reverse; } }
    .hero-flexbox-text-area {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 60em) {
        .hero-flexbox-text-area {
          width: 100%; } }
    .hero-flexbox .hero-text-ul {
      position: relative; }
      .hero-flexbox .hero-text-ul:before {
        content: '<ul>  ';
        font-family: 'Handlee', cursive;
        color: #97d783;
        font-size: 2rem; }
      .hero-flexbox .hero-text-ul:after {
        content: '  </ul>';
        font-family: 'Handlee', cursive;
        color: #97d783;
        font-size: 2rem; }
      .hero-flexbox .hero-text-ul .hero-text-li {
        margin-left: 3rem;
        font-size: 3rem; }
        @media (max-width: 37.5em) {
          .hero-flexbox .hero-text-ul .hero-text-li {
            font-size: 2rem; } }
        .hero-flexbox .hero-text-ul .hero-text-li:before {
          content: '<li>  ';
          font-family: 'Handlee', cursive;
          color: #97d783;
          font-size: 1.6rem; }
        .hero-flexbox .hero-text-ul .hero-text-li:after {
          content: '  </li>';
          font-family: 'Handlee', cursive;
          color: #97d783;
          font-size: 1.6rem; }
    .hero-flexbox-image-area {
      width: 35%; }
      @media (max-width: 60em) {
        .hero-flexbox-image-area {
          width: 100%;
          margin-bottom: 5rem;
          display: flex;
          align-items: center;
          justify-content: center; } }
      .hero-flexbox-image-area .caricature {
        height: 30rem;
        width: 30rem;
        border-radius: 50%;
        background: url(../src/Components/HeroSection/caricature.png);
        background-size: cover;
        background-repeat: no-repeat; }
        @media (max-width: 37.5em) {
          .hero-flexbox-image-area .caricature {
            height: 25rem;
            width: 25rem; } }
  .hero-sphere {
    position: absolute;
    top: -35rem;
    right: -35rem;
    width: 100%;
    height: 120%;
    background: radial-gradient(#bee6b1, #aee09e 20%, #5ab93b 80%);
    border-radius: 50%;
    transition: all 2s ease-in-out;
    animation: bobble 5s infinite;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 60em) {
      .hero-sphere {
        width: 100%;
        height: 80%;
        top: -15rem;
        right: -15rem; } }
    .hero-sphere-inner {
      width: 75%;
      height: 75%;
      background: radial-gradient(#f0e094, #ebd570 30%, #b79b1a 80%);
      border-radius: 50%; }
  .hero-text {
    position: absolute;
    padding-left: 5rem;
    padding-top: 12rem; }
    @media (max-width: 60em) {
      .hero-text {
        padding-left: 1.5rem; } }
    .hero-text-name {
      margin-bottom: 1.5rem;
      margin-top: 12rem;
      color: #222;
      line-height: 1.7; }
      @media (max-width: 60em) {
        .hero-text-name {
          line-height: 1.1; } }
    .hero-text-desc {
      font-weight: 400;
      color: #222;
      line-height: 1.4; }
      @media (max-width: 60em) {
        .hero-text-desc {
          font-size: 2.5rem; } }
  .hero-wave {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    fill: #fff; }

@keyframes bobble {
  0% {
    transform: scale(1.001);
    background-color: #77cb5c; }
  50% {
    transform: scale(1.01);
    background-color: #e0bf24; } }

.about {
  text-align: center;
  position: relative; }
  .about .btn {
    margin-bottom: 12rem; }
    @media (max-width: 60em) {
      .about .btn {
        margin-bottom: 5rem; } }
  .about-wave {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    fill: #e0bf24; }

.skills {
  background-color: #e0bf24;
  text-align: center;
  position: relative; }
  .skills-card {
    padding: 5rem 3rem;
    background-color: #fff;
    margin: 0 1.5rem 12rem;
    min-width: 22rem; }
    @media (max-width: 60em) {
      .skills-card {
        margin: 3rem auto 0; } }
  .skills-icon {
    font-size: 3rem;
    color: #e0bf24;
    margin-bottom: 1.5rem; }
  .skills-wave {
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    fill: #fff; }

.projects {
  text-align: center; }

.card {
  perspective: 180rem;
  -moz-perspective: 180rem;
  position: relative;
  height: 44rem;
  margin: 0 3rem;
  min-width: 36rem; }
  @media (max-width: 60em) {
    .card {
      margin: 1.5rem; } }
  .card-side {
    height: 44rem;
    min-width: 36rem;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 0.2rem;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(51, 51, 51, 0.2); }
    .card-side--front {
      color: #222; }
    .card-side--back {
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .card-side--back-1 {
        background-image: linear-gradient(to right bottom, #bd338e, #d053a6, #451233); }
      .card-side--back-2 {
        background-image: linear-gradient(to right bottom, #77cb5c, #97d783, #346c22); }
      .card-side--back-3 {
        background-image: linear-gradient(to right bottom, #e0bf24, #e6cc51, #5e500d); }
  .card:hover .card-side--front {
    transform: rotateY(-180deg); }
  .card:hover .card-side--back {
    transform: rotateY(0); }
  .card-img {
    background-size: cover;
    height: 55%;
    background-blend-mode: screen;
    border-bottom: #eee 0.1rem solid; }
    .card-img-1 {
      background-image: url("https://i.imgur.com/Pa2qh4e.gif"); }
    .card-img-2 {
      background-image: url("https://i.imgur.com/8fQa2tk.gif"); }
  .card-project-overview {
    padding: 3rem; }
  .card-project-heading {
    margin-bottom: 1.5rem;
    color: #333;
    padding: 0.3rem; }
    .card-project-heading-1 {
      background-color: rgba(189, 51, 142, 0.7); }
    .card-project-heading-2 {
      background-color: rgba(119, 203, 92, 0.7); }
    .card-project-heading-3 {
      background-color: rgba(224, 191, 36, 0.7); }
  .card-details ul {
    width: 70%;
    margin: 5rem auto; }
    .card-details ul li {
      padding-top: 0.5rem; }
  .card-links {
    margin-bottom: 5rem; }
    .card-links-icon {
      font-size: 3.5rem;
      margin: 1.5rem;
      color: #333;
      transition: all 1s; }
      .card-links-icon:hover {
        color: #fff;
        transform: scale(1.2); }

.contact-container {
  display: flex;
  width: 100%; }
  @media (max-width: 37.5em) {
    .contact-container {
      flex-direction: column; } }

.contact-img-area {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between; }
  @media (max-width: 37.5em) {
    .contact-img-area {
      width: 100%;
      margin-bottom: 3rem; } }
  .contact-img-area img {
    margin: 0 auto;
    padding-bottom: 5rem;
    width: 80%; }

.contact-form-area {
  width: 50%;
  position: relative; }
  @media (max-width: 37.5em) {
    .contact-form-area {
      width: 100%; } }
  .contact-form-area form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    font-size: 1.6rem; }
    @media (max-width: 37.5em) {
      .contact-form-area form {
        width: 100%; } }
    .contact-form-area form .input-style {
      border: 0.1rem solid #eee;
      padding: 0.7rem;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.6rem;
      line-height: 1.2;
      color: #222; }
      .contact-form-area form .input-style:focus {
        outline: none;
        border: 0.1rem solid #77cb5c; }
    .contact-form-area form input {
      margin-bottom: 1.5rem; }
    .contact-form-area form button {
      align-self: flex-end;
      margin-top: 1.5rem;
      min-height: 3rem;
      width: 14rem; }

.small {
  font-size: 0.9rem;
  color: #bbb;
  text-align: end;
  margin-top: 0.3rem; }
  .small a {
    color: rgba(76, 139, 245, 0.8);
    text-decoration: none; }

.grecaptcha-badge {
  visibility: hidden; }

.floating-alerts .floating-alert:last-of-type {
  display: block; }

.alert-success {
  background-color: #00cc00; }

.alert-warning {
  background-color: #ff9800; }

.floating-alert {
  display: none;
  position: absolute;
  z-index: 600;
  bottom: -5rem;
  right: 3rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: floatingAlert ease-in-out 4s forwards; }

@keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden; }
  20% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2); } }

.volunteer-info,
.education-info {
  width: 60%;
  padding: 0 1.5rem; }
  @media (max-width: 60em) {
    .volunteer-info,
    .education-info {
      width: 100%; } }
  .volunteer-info h3,
  .education-info h3 {
    margin-top: 1.5rem; }
  .volunteer-info .date,
  .education-info .date {
    color: #bd338e; }
  .volunteer-info ul,
  .education-info ul {
    list-style-type: circle;
    margin-left: 2rem; }
    .volunteer-info ul li span,
    .education-info ul li span {
      position: relative;
      left: -0.5rem; }

.img-area {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .img-area img {
    width: 80%; }
  @media (max-width: 60em) {
    .img-area {
      width: 75%;
      margin: 5rem auto 0; } }

.flex-reverse {
  flex-direction: row-reverse; }
  @media (max-width: 60em) {
    .flex-reverse {
      flex-direction: column; } }
  .flex-reverse .bold {
    font-weight: bold; }
  .flex-reverse .btn-outline {
    margin-top: 1.5rem;
    width: 60%;
    font-size: 1.8rem; }

.footer {
  display: flex;
  margin: 3rem 0;
  align-items: center;
  justify-content: center; }
  .footer .links-container {
    margin: 0 5rem; }
    .footer .links-container .footer-icon {
      font-size: 2.5rem;
      margin: 0 1.5rem;
      color: #222;
      transition: all 1s; }
      .footer .links-container .footer-icon:hover {
        color: #bd338e;
        transform: scale(1.2); }
